<template>
  <div class="px-8 py-8 container" style="max-width: 1150px;margin:0 auto;">
    <div class="flex flex-wrap">
      <div class="w-full sm:w-4/5">
        <TitleComponent
          class="py-8"
          title="UN Thermal stability test at 75 °C"
          size="large"
        />

        <div class="flex flex-wrap mt-8 p-4" style="background-color:#f7f5f4;">
          <div class="w-full">
            <TitleComponent
              class="py-8"
              title="Test Description"
              size="medium"
            />
            <a
              :href="
                require('../../assets/UN/SubstanceThermalStabilityDetailed-min.png')
              "
              target="_blank"
            >
              <img
                class="m-auto"
                src="@/assets/UN/SubstanceThermalStabilityDetailed-min.png"
              />
            </a>
          </div>
          <div class="w-full">
            <TitleComponent class="py-8" title="Test Purpose" size="medium" />
            <p class="p-light">
              This test is used to measure the thermal stability of the
              substance when subjected to elevated thermal conditions.
            </p>
            <TitleComponent
              class="py-8"
              title="Test Variations"
              size="medium"
            />
            <p class="p-light">
              In the uninstrumented version of the UN Test 3 (c) (i), a
              substance is placed in a glass beaker and heated at 75°C for 48
              hours. If there is any evidence of decomposition, then the
              instrumented version of the test must be completed. Test 3 (c)
              (ii) is an alternative thermal stability test that uses a smaller
              sample size.
            </p>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full">
            <TitleComponent class="pt-8" title="Key Parameters" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Key Parameter</th>
                    <th>Objectives</th>
                    <th>Origin</th>
                    <th>Specs</th>
                  </tr>
                  <tr>
                    <td>
                      Test temperature of the substance and time of exposure to
                      that temperature
                    </td>
                    <td>
                      Determine the stability of a substance using exposure time
                      and temperatures to mimic potential conditions of
                      transport
                    </td>
                    <td></td>
                    <td>Substance is held at 75°C for 48 hours.</td>
                  </tr>
                  <tr>
                    <td>Sample holder</td>
                    <td>Contain the sample</td>
                    <td></td>
                    <td>
                      Uninstrumented: A lipless beaker of 35 mm diameter and 50
                      mm high; Instrumented: Two flat-bottomed glass tubes of 50
                      ± 1 mm diameter and 150 mm in length with two 60 kPa
                      pressure resisting stoppers
                    </td>
                  </tr>
                  <tr>
                    <td>Watch glass</td>
                    <td>
                      Provide witness if fuming occurs (watch glass may be
                      discolored); watch glass allows venting but limits
                      absorption of moisture while cooling (masking mass loss)
                    </td>
                    <td>
                      <small
                        >Uncovered samples can absorb moisture from the air
                        while cooling</small
                      >
                    </td>
                    <td>
                      Uninstrumented: 40 mm in diameter; Instrumented: not
                      applicable
                    </td>
                  </tr>
                  <tr>
                    <td>Sample mass</td>
                    <td>
                      Provide sufficient mass to enable accurate detection of
                      thermal instability
                    </td>
                    <td></td>
                    <td>Uninstrumented: 50 grams Instrumented: 100 grams</td>
                  </tr>
                  <tr>
                    <td>Test oven</td>
                    <td>
                      Ensure adequate temperature control and protection against
                      thermal runaway
                    </td>
                    <td></td>
                    <td>
                      An electric oven equipped with ventilation,
                      explosion-proof electrical features, and thermostatic
                      control adequate to maintain and record the temperature at
                      75°C ± 2°C. The oven should have dual thermostats or some
                      kind of protection against thermal run-away if the
                      thermostat malfunctions
                    </td>
                  </tr>
                  <tr>
                    <td>Self-heating temperature change</td>
                    <td>
                      Parameter for characterizing the thermal stability of a
                      material
                    </td>
                    <td></td>
                    <td>Uninstrumented: not applicable; Instrumented: 3°C</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <TitleComponent class="pt-8" title="Indicators" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Indicators</th>
                    <th>Detection Method</th>
                    <th>Assessment*</th>
                  </tr>
                  <tr>
                    <td>Ignition or explosion</td>
                    <td>Visual post-test inspection</td>
                    <td>
                      Damage to sample holder, flame traces, consumption of
                      sample, etc. Forbidden
                    </td>
                  </tr>
                  <tr>
                    <td>Fuming or decomposition</td>
                    <td>Visual post-test inspection</td>
                    <td>Discoloration of watch glass or sample</td>
                  </tr>
                  <tr>
                    <td>Substance temperature (not always required)</td>
                    <td>Thermocouple</td>
                    <td>
                      Difference between reference and sample of 3°C or greater
                      is evidence of self-heating. Forbidden
                    </td>
                  </tr>
                </tbody>
              </table>
              <p class="p-light">*OR relationship</p>
            </div>
          </div>
        </div>
      </div>
      <UnSideBar class="w-full sm:w-1/5"></UnSideBar>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import UnSideBar from "@/components/UnSideBar";

export default {
  components: {
    TitleComponent,
    UnSideBar
  }
};
</script>

<style lang="scss"></style>
